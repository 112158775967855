import React, { useState, useEffect } from 'react';
import { Row, Col, DropdownButton, Dropdown, Container, InputGroup, FormControl, Button } from "react-bootstrap";
import * as storeActions from "../../../store/actions/index";
import { connect } from "react-redux";
import NavBreadcrumb from "../../Base/Breadcrumb/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faGlasses, faCheck, faTimes, } from "@fortawesome/free-solid-svg-icons";
import MyTable from "../../Tables/MyTable/DataTableRanking";
import Card from "../../Cards/Card";
import ModalRanking from './ModalRanking/ModalRanking'

const timestampToDate = (dataIn) => {
  try {
    let data = new Date(dataIn * 1000)
    data.setHours(data.getHours() - 3)
    data = data.toISOString().replace(/T/, ' ').replace(/\..+/, '').replace("Z", '');
    let newData = data.split(" ");
    let newFormData = newData[0].split("-");
    let outPutData = newFormData[2] + "/" + newFormData[1] + "/" + newFormData[0] + " " + newData[1]
    return outPutData;
  }
  catch (e) {
    return "31/12/2099 23:59:59"
  }
}

const formatTableData = (data, items) => {
  if (!data) {
    return [];
  }
  const array = data.map((el) => {
    const result = {
      index: el.index,
      description: el.description,
      dateStart: el.dateStart,
      dateEnd: el.dateEnd,
      status: el.status,
      match: el.match,
      itemPrice: el.itemPrice,
      origin: el.origin,
      declined: el.declined,
      portalId: el.portalId,
      files: el.files,
      fileId: el.fileId,
      proposalId: el.proposalId,
      _id: el._id,
      totalPrice: el.totalPrice,
      localStatus: el.localStatus
    };
    return result;
  });
  return array;
};

const filterByRegex = (regexBTN, originF) => {
  let filtered = []
  if (regexBTN.length !== 0) {
    originF.map((item) => {

      if (item.match.length > 0) {
        item.match.map(obj => {
          if (regexBTN == obj.manufacturer) {
            filtered.push(item);
          }
        })
      }
    });
  }
  return filtered;
}

const filterByDate = (di, df, data, formSearch) => {
  let filtered = [];
  if (di !== "") {
    const today = df;
    const tomorrow = new Date(today)
    let newDateFrom = new Date(di + " 00:00:00");
    let newDateTo = (df === "") ? (new Date(di + " 23:59:59")) : (new Date(df + " 23:59:59"));

    data.map((item) => {
      if (formSearch === "Data Início") {
        let newDateStart = new Date(item.dateStart * 1000);
        newDateStart.setHours(newDateStart.getHours() - 3);
        if (newDateStart.getTime() >= newDateFrom.getTime() && newDateStart.getTime() <= newDateTo.getTime()) {
          filtered.push(item);
        }
      } else if (formSearch === "Data Fim") {
        let newDateEnd = new Date(item.dateEnd * 1000);
        newDateEnd.setHours(newDateEnd.getHours() - 3);
        if (newDateEnd.getTime() >= newDateFrom.getTime() && newDateEnd.getTime() <= newDateTo.getTime()) {
          filtered.push(item);
        }
      } else {
        filtered.push(item);
      }
    });
    return filtered;
  } else {
    return data;
  }
}

const portalSet = (opps, currentPortaIdlUser) => {
  return (opps.filter((el) => el.portalId === currentPortaIdlUser));
}

const itemSet = (obj, props) => {
  let output = []
  obj.dataItems.map(opItem => {
    let opportunityObj = obj.dashboardData.find(o => opItem.opportunityId === o._id)
    let portalObj = props.portals.find(o => opItem.portalId._id === o._id)
    opItem["opportunityId"] = opportunityObj !== undefined ? opportunityObj : opItem["opportunityId"]
    opItem["portalId"] = portalObj !== undefined ? portalObj : opItem["portalId"]
    output.push(opItem);
  })
  return (output);
}

function Ranking(props) {
  let currentPortaIdlUser;
  let currentCompany = "";
  props.portals.map(portal => {
    if (portal._id === props.user.data.selectedPortalId) {
      currentPortaIdlUser = portal._id;
      currentCompany = portal.companyName
    }
  })

  // let currentCompany;
  // props.companies.map(data => {
  //   if (data._id === props.user.data.companyId) {
  //     currentCompany = data.name
  //   }
  // })

  // console.log(currentCompany)

  let currentCompanyName = currentCompany.split(' ')[0]
  currentCompanyName = currentCompanyName[0].toUpperCase() + currentCompanyName.substr(1).toLocaleLowerCase()

  const [proposalSelectedData, setProposalSelectedData] = useState(false)
  const [tableRender, setTableRender] = useState(false)
  const [loading, setLoading] = useState(false);
  const [formSearch, setFormSearch] = useState("Data Fim");
  const [tipoFiltro, setTipoFiltro] = useState("Tudo");
  const [formField, setFormField] = useState("Tudo");
  const [mesSelecionado, setMesSelecionado] = useState("Janeiro");
  const [anoSelecionado, setAnoSelecionado] = useState("2021");
  const [trimestreSelecionado, setTrimestreSelecionado] = useState("1º Trimestre");
  const [semestreSelecionado, setSemestreSelecionado] = useState("1º Semestre");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [regexBTN, setRegexBTN] = useState([]);
  const [resultProposals, setResultProposals] = useState([]);
  const [btnSearchPastOpportunities, setBtnSearchPastOpportunities] = useState(false);
  const [pastOpportunity, setPastOpportunity] = useState({
    "dashboardData": [],
    "dataItems": []
  });
  const [inputData, setInputData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [concorrentes, setConcorrentes] = useState([]);
  const [opId, setOpId] = useState("");
  const [dataConcorrente, setDataConcorrente] = useState([]);
  const [nomeTop3Concorrente, setNomeTop3Concorrente] = useState([]);
  const [selected, setSelected] = useState("");
  const [itemSelect, setItemSelect] = useState("");
  const [selectedTable, setSelectedTable] = useState("");
  const [itensConcorrenteSelecionado, setItensConcorrenteSelecionado] = useState([]);
  //const [dataEmpresa, setDataEmpresa] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [fullDataTop3, setFullDataTop3] = useState([]);
  const [fullDataTop3Table, setFullDataTop3Table] = useState([]);
  const [tag, setTag] = useState(false);
  const [dataManufacturers, setDataManufacturers] = useState("");
  const [isMultiItemState, setIsMultiItemState] = useState(false);
  const [controlItemsManufacturersData, setControlItemsManufacturersData] = useState([]);
  const [_opNumber, _setOpNumber] = useState('')
  const [opNumber, setOpNumber] = useState(undefined)
  const [rangeOfMonths, setRangeOfMonths] = useState({})
  const [data, setData] = useState(null)
  const [dataTimestamp, setDataTimestamp] = useState(null)
  const [dataBar, setDataBar] = useState({
    series: [{
      name: 'Net Profit',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
    }, {
      name: 'Revenue',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
    }, {
      name: 'Free Cash Flow',
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      },
      yaxis: {
        title: {
          text: '$ (thousands)'
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          }
        }
      }
    },
  });
  const [dataPie, setDataPie] = useState({
    options: {
      labels: ["Apple", "Mango", "Orange", "Watermelon"],
      theme: {
        monochrome: {
          enabled: true,
          color: '#0000cd',
          shadeTo: 'light',
          shadeIntensity: 0.65
        }
      },
      responsive: [
        {
          breakpoint: 360,
          options: {
            chart: {
              width: "100%"
            },
            legend: {
              show: false
            }
          }
        }
      ],
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            // console.log(config.w.config.labels[config.dataPointIndex]);
          }
        }
      }
    },
    series: [44, 55, 13, 43]
  });
  const originTab = "Minhas Participações"
  const opportunitiesColumns = [
    {
      name: "Número",
      selector: "index",
      sortable: true,
    },
    {
      name: "Desc. do Objeto",
      selector: "description",
      sortable: true,
      overlay: true
    },
    {
      name: "Dt Início",
      selector: "dateStart",
      sortable: true,
    },
    {
      name: "DT Fim",
      selector: "dateEnd",
      sortable: true,
    },
    {
      name: "Origem",
      selector: "origin",
      sortable: true,
    },
    {
      name: "CC",
      selector: "CC",
      sortable: true
    },
    {
      name: "Anexo",
      selector: "anexo",
      sortable: true
    },
    {
      name: "✓",
      selector: "✓",
      sortable: true
    },
    {
      name: "R$",
      selector: "R$",
      sortable: true
    },
    {
      name: "Status",
      selector: "_Status",
      sortable: true
    },
  ]

  useEffect(() => {
    if (_opNumber > 7000000000 && _opNumber < 7009999999) {
      setOpNumber(_opNumber)
    }
  }, [_opNumber])

  let dataEmpresa = [];

  /* Gerando anos automaticamente a partir de 2021 */
  let year = [];
  for (let i = 2021; i <= new Date().getFullYear(); i++) {
    year.push(i)
  }

  const handleGetPastOpportunities = async (
    mesSelecionado,
    dateFrom,
    dateTo,
    formSearch,
    anoSelecionado,
    trimestreSelecionado,
    semestreSelecionado,
    props) => {
      setTableRender(false);

    if (formSearch !== "Nº da Oportunidade") {
      setOpNumber(undefined)
      _setOpNumber('')
    } else {
      setRangeOfMonths({}); //selecionando intervalo de meses para filtro por fabricante
    }

    let numMes = 0;
    switch (mesSelecionado) {
      case "Janeiro":
        numMes = 1;
        break;
      case "Fevereiro":
        numMes = 2;
        break;
      case "Março":
        numMes = 3;
        break;
      case "Abril":
        numMes = 4;
        break;
      case "Maio":
        numMes = 5;
        break;
      case "Junho":
        numMes = 6;
        break;
      case "Julho":
        numMes = 7;
        break;
      case "Agosto":
        numMes = 8;
        break;
      case "Setembro":
        numMes = 9;
        break;
      case "Outubro":
        numMes = 10;
        break;
      case "Novembro":
        numMes = 11;
        break;
      case "Dezembro":
        numMes = 12;
        break;
      default:
        numMes = 1;
        break;
    }

    let _dateFrom = dateFrom.split("-")
    let _dateTo = dateTo.split("-")
    let newDateFrom = new Date(_dateFrom[0], _dateFrom[1] - 1, _dateFrom[2], "0", "0", "0");
    let newDateTo = new Date(_dateTo[0], _dateTo[1] - 1, _dateTo[2], "23", "59", "59");
    let dashboardData = []
    let dateNow = new Date();
    let seletor = formSearch;

    if (seletor === "Mês") {
      newDateFrom = new Date(anoSelecionado, numMes - 1, 1, "0", "0", "0");
      dateNow.setMonth(numMes - 1);
      dateNow.setDate(1);
      let yesterdayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
      newDateTo = new Date(anoSelecionado, yesterdayMonth.getMonth(), yesterdayMonth.getDate(), "23", "59", "59");
      //selecionando intervalo de meses para filtro por fabricante
      setRangeOfMonths({
        "dateFrom": newDateFrom / 1000,
        "dateTo": newDateTo / 1000
      });
    } else if (seletor === "Trimestre") {
      switch (trimestreSelecionado) {
        case ("1º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(0);
          let trimestre1 = new Date(anoSelecionado, dateNow.getMonth() + 4, 0);
          trimestre1.setDate(1);
          newDateTo = new Date(anoSelecionado, trimestre1.getMonth(), trimestre1.getDate() - 1, "23", "59", "59");

          //selecionando intervalo de meses para filtro por fabricante
          setRangeOfMonths({
            "dateFrom": newDateFrom / 1000,
            "dateTo": newDateTo / 1000
          });
          break;
        case ("2º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 3, 1, "0", "0", "0");
          dateNow.setMonth(4);
          let trimestre2 = new Date(anoSelecionado, dateNow.getMonth() + 3, 0);
          trimestre2.setDate(1);
          newDateTo = new Date(anoSelecionado, trimestre2.getMonth(), trimestre2.getDate() - 1, "23", "59", "59");

          //selecionando intervalo de meses para filtro por fabricante
          setRangeOfMonths({
            "dateFrom": newDateFrom / 1000,
            "dateTo": newDateTo / 1000
          });
          break;
        case ("3º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 6, 1, "0", "0", "0");
          dateNow.setMonth(7);
          let trimestre3 = new Date(anoSelecionado, dateNow.getMonth() + 3, 0);
          trimestre3.setDate(1);
          newDateTo = new Date(anoSelecionado, trimestre3.getMonth(), trimestre3.getDate() - 1, "23", "59", "59");

          //selecionando intervalo de meses para filtro por fabricante
          setRangeOfMonths({
            "dateFrom": newDateFrom / 1000,
            "dateTo": newDateTo / 1000
          });
          break;
        case ("4º Trimestre"):
          newDateFrom = new Date(anoSelecionado, 9, 1, "0", "0", "0");
          dateNow.setMonth(10);
          let trimestre4 = new Date(anoSelecionado, dateNow.getMonth() + 3, 0);
          trimestre4.setDate(1);
          newDateTo = new Date(String(parseInt(anoSelecionado) + 1), trimestre4.getMonth(), trimestre4.getDate() - 1, "23", "59", "59");

          //selecionando intervalo de meses para filtro por fabricante
          setRangeOfMonths({
            "dateFrom": newDateFrom / 1000,
            "dateTo": newDateTo / 1000
          });
          break;
        default:
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(4);
          dateNow.setDate(dateNow.getDate() - 1);
          newDateTo = new Date(anoSelecionado, dateNow.getMonth() - 1, dateNow.getDate(), "23", "59", "59");

          //selecionando intervalo de meses para filtro por fabricante
          setRangeOfMonths({
            "dateFrom": newDateFrom / 1000,
            "dateTo": newDateTo / 1000
          });
      }
    } else if (seletor === "Semestre") {
      switch (semestreSelecionado) {
        case ("1º Semestre"):
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(7);
          let semestre1 = new Date(anoSelecionado, dateNow.getMonth(), 0);
          dateNow.setDate(1);
          newDateTo = new Date(anoSelecionado, semestre1.getMonth() - 1, semestre1.getDate() - 1, "23", "59", "59");

          //selecionando intervalo de meses para filtro por fabricante
          setRangeOfMonths({
            "dateFrom": newDateFrom / 1000,
            "dateTo": newDateTo / 1000
          });
          break;
        case ("2º Semestre"):
          newDateFrom = new Date(anoSelecionado, 6, 1, "0", "0", "0");
          dateNow.setMonth(13);
          let semestre2 = new Date(anoSelecionado, dateNow.getMonth(), 0);
          dateNow.setDate(1);
          newDateTo = new Date(String(parseInt(anoSelecionado) + 1), semestre2.getMonth() - 1, semestre2.getDate(), "23", "59", "59");

          //selecionando intervalo de meses para filtro por fabricante
          setRangeOfMonths({
            "dateFrom": newDateFrom / 1000,
            "dateTo": newDateTo / 1000
          });
          break;
        default:
          newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
          dateNow.setMonth(7);
          dateNow.setDate(dateNow.getDate() - 1);
          newDateTo = new Date(anoSelecionado, dateNow.getMonth() - 1, dateNow.getDate(), "23", "59", "59");

          //selecionando intervalo de meses para filtro por fabricante
          setRangeOfMonths({
            "dateFrom": newDateFrom / 1000,
            "dateTo": newDateTo / 1000
          });
          break;
      }
    } else {
      newDateFrom = new Date(anoSelecionado, 0, 1, "0", "0", "0");
      newDateTo = new Date(anoSelecionado, 11, "31", "23", "59", "59");

      //selecionando intervalo de meses para filtro por fabricante
      setRangeOfMonths({
        "dateFrom": newDateFrom / 1000,
        "dateTo": newDateTo / 1000
      });
    }

    if (opNumber !== undefined && formSearch === "Nº da Oportunidade") {
      // await props.getProposals(props.user, newDateFrom / 1000, newDateTo / 1000).then((val) => { setResultProposals(val) });
      await props.getProposalById(props.user, opNumber).then((val) => { setResultProposals([val]) });
      await props.setPastOpportunities(props.user, currentPortaIdlUser, 0, 0, opNumber).then((val) => {
        dashboardData = val
      });
    } else {
      await props.getProposals(props.user, newDateFrom / 1000, newDateTo / 1000).then((val) => { setResultProposals(val) });
      await props.setPastOpportunities(props.user, currentPortaIdlUser, newDateFrom / 1000, newDateTo / 1000, undefined).then((val) => {
        dashboardData = val
      });
    }

    // console.log(dashboardData);
    setData(dashboardData)

    let dataItems = [];
    dashboardData.map(o => {
      let items = o.items;
      if (items) {
        items.map(i => {
          if (i) {
            dataItems.push(i);
          }
        })
      }
    });
    return {
      "dataItems": dataItems,
      "dashboardData": dashboardData
    }

    setTableRender(true);
  }

  async function fillData() {
    localStorage.setItem("selectedPageRanking", 0)
    setLoading(true)
    setBtnSearchPastOpportunities(true)
    // if (resultProposals.length === 0) {
    //   await props.getProposals(props.user, dateFrom, dateTo).then((val) => { setResultProposals(val) });
    // }

    setPastOpportunity(await handleGetPastOpportunities(
      mesSelecionado,
      dateFrom,
      dateTo,
      formSearch,
      anoSelecionado,
      trimestreSelecionado,
      semestreSelecionado,
      props));

    setBtnSearchPastOpportunities(false)
    setLoading(false)
  }

  useEffect(() => {
    // Seleciona dados do portal selecionado
    setInputData(portalSet(pastOpportunity.dashboardData, currentPortaIdlUser));

    // Merge entre Oportunidades e Itens
    setDataItems(itemSet(pastOpportunity, props));

    // Filtros disponíveis
    const byDateF = filterByDate(dateFrom, dateTo, inputData, formSearch);
    const originF = byDateF.filter(o => o.origin === originTab);
    const catalogF = filterByRegex(regexBTN, originF, dataItems);
    // Unifica todos os dados do filtro
    let lake = (catalogF.length > 0) ? catalogF : originF;
    setDataTimestamp(originF)

    // Verificando se devemos acionar o filtro
    var filtersApplied = true;
    if (
      catalogF.length === 0 &&
      byDateF.length === 0 &&
      originF.length === 0 &&
      regexBTN.length === 0
    ) {
      filtersApplied = false;
    }
    // Sinalização de cores nos números de oportunidade
    setTableData(formatTableData(                              // Formata para tabela
      (filtersApplied ? lake : inputData),
      dataItems
    ));
  }, [pastOpportunity.length > 0, tableData.length > 0, dataItems.length > 0, btnSearchPastOpportunities, regexBTN]);

  let filtroList = []

  /* ------------------- Função chamada após o click no óculos -------------------- */
  function findProposal(index, proposal) {
    //formatando dados para o gráfico
    let orgConcorrentes = {}
    proposal.map(item => {
      if (orgConcorrentes[item["corporateName"]] === undefined) {
        orgConcorrentes[item["corporateName"]] = []
      }
      orgConcorrentes[item["corporateName"]].push(item)
    })
    setDataManufacturers(orgConcorrentes)

    /* ----------------- Armazenando os dados da empresa logada -------------------------------- */

    let currentCompanyData = []
    let _dataEmpresa = []
    let founded = false;
    Object.keys(orgConcorrentes).map(key => {
      
      if(key.includes(currentCompany.split(" ")[0])){
        // console.log(orgConcorrentes[key])
        currentCompany = key;
        founded = true
        currentCompanyData.push({
          "corporateName": orgConcorrentes[key][0].corporateName,
          "itemDescription": orgConcorrentes[key][0].itemDescription,
          "quantity": orgConcorrentes[key][0].quantity,
          "itemPrice": orgConcorrentes[key][0].itemPrice,
          "totalPrice": orgConcorrentes[key][0].totalPrice
        })
      }else{
        _dataEmpresa.push({
          "corporateName": orgConcorrentes[key][0].corporateName,
          "itemDescription": orgConcorrentes[key][0].itemDescription,
          "quantity": orgConcorrentes[key][0].quantity,
          "itemPrice": orgConcorrentes[key][0].itemPrice,
          "totalPrice": orgConcorrentes[key][0].totalPrice
        })
      }
    })

    // console.log(orgConcorrentes)

    if(founded == false){
      // console.log("err")
      // dataEmpresa.push({
      //   "corporateName": currentCompany,
      //   "itemDescription": _dataEmpresa[0]["itemDescription"],
      //   "quantity": 0,
      //   "itemPrice": 0,
      //   "totalPrice": 0
      // })
    }else{
      currentCompanyData.map(o => {
        dataEmpresa.push(o)
      })
      
    }

    // console.log(currentCompanyData)


    // let _dataEmpresa = []
    // for (var prop in orgConcorrentes) {
    //   for (let i = 0; i < orgConcorrentes[prop].length; i++) {
    //     if (orgConcorrentes[prop][i].corporateName === currentCompany) {

    //       currentCompany = orgConcorrentes[prop][i].corporateName;

    //       _dataEmpresa.push({
    //         "corporateName": orgConcorrentes[prop][i].corporateName,
    //         "itemDescription": orgConcorrentes[prop][i].itemDescription,
    //         "quantity": orgConcorrentes[prop][i].quantity,
    //         "itemPrice": orgConcorrentes[prop][i].itemPrice,
    //         "totalPrice": orgConcorrentes[prop][i].totalPrice
    //       })
    //     }
    //   }
    // }

    // console.log(orgConcorrentes)
    // console.log(_dataEmpresa)
    // dataEmpresa = orgConcorrentes.map(o => {
    //   return({
    //     "corporateName": o.corporateName,
    //     "itemDescription": o.itemDescription,
    //     "quantity": o.quantity,
    //     "itemPrice": o.itemPrice,
    //     "totalPrice": o.totalPrice
    //   })
    // })

    /* -------------------------- Gerando nome, item e preço cotado por cada concorrente ---------------------- */
    let total = []
    for (var prop in orgConcorrentes) {
      for (let i = 0; i < orgConcorrentes[prop].length; i++) {
        if (orgConcorrentes[prop][i].totalPrice !== 0) {
          total.push({
            "corporateName": orgConcorrentes[prop][i].corporateName,
            "currency": orgConcorrentes[prop][i].currency,
            "itemDescription": orgConcorrentes[prop][i].itemDescription,
            "itemNumber": orgConcorrentes[prop][i].itemNumber,
            "itemPrice": orgConcorrentes[prop][i].itemPrice,
            "proposalNumber": orgConcorrentes[prop][i].proposalNumber,
            "quantity": orgConcorrentes[prop][i].quantity,
            "taxCode": orgConcorrentes[prop][i].taxCode,
            "totalPrice": orgConcorrentes[prop][i].totalPrice,
            "um": orgConcorrentes[prop][i].um
          })
        }
      }
    }

    // console.log(dataEmpresa)
    /* ----------- Armazenando descrição dos itens cotados desta oportunidade ------------ */
    let dataTotalItens = [];
    dataEmpresa.map((data) => {
      if (!dataTotalItens.includes(data.itemDescription)) {
        dataTotalItens.push(data.itemDescription)
      }
    })

    /* ----------- Armazenando a quantidade de cada item desta oportunidade ------------ */
    let dataTotalQuantity = [];
    for (let i = 0; i < dataEmpresa.length; i++) {
      dataTotalQuantity.push(dataEmpresa[i].quantity)
    }

    /* -------------------------- Setando a base de dados do gráfico de pizza ------------------------------ */
    let datasetPie = {
      options: {
        labels: dataTotalItens,
        theme: {
          monochrome: {
            enabled: true,
            color: '#0000cd',
            shadeTo: 'light',
            shadeIntensity: 0.65
          }
        },
        title: {
          text: "Itens da oportunidade com base na quantidade solicitada"
        },
        responsive: [
          {
            breakpoint: 360,
            options: {
              chart: {
                width: "100%"
              },
              legend: {
                show: false
              }
            }
          }
        ],
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              // console.log(config.w.config.labels[config.dataPointIndex]);
            }
          }
        }
      },
      series: dataTotalQuantity
    }
    setDataPie(datasetPie)

    /* -------------------------------- Preenchendo o select de itens ------------------------ */
    setItensConcorrenteSelecionado(dataTotalItens)

    /* --------------------- Separando cotações realizadas por item ---------------------------- */
    let controlItemsManufacturers = {};
    dataTotalItens.map(item => {
      controlItemsManufacturers[item] = []
      total.map(data => {
        if (data.itemDescription === item) {
          controlItemsManufacturers[item].push({
            "corporateName": data.corporateName,
            "currency": data.currency,
            "itemDescription": data.itemDescription,
            "itemNumber": data.itemNumber,
            "itemPrice": data.itemPrice,
            "proposalNumber": data.proposalNumber,
            "quantity": data.quantity,
            "taxCode": data.taxCode,
            "totalPrice": data.totalPrice,
            "um": data.um
          })
        }
      })
    })
    for (let cont in controlItemsManufacturers) {
      controlItemsManufacturers[cont].sort(function (a, b) {
        if (a.totalPrice > b.totalPrice) {
          return true;
        } else {
          return -1;
        }
      })
      if (controlItemsManufacturers[cont].length === 0) {
        delete (controlItemsManufacturers[cont]);
      }
    }
    setControlItemsManufacturersData(controlItemsManufacturers)

    // console.log(controlItemsManufacturers)

    /*------------ Gerando o nome do fornecedor, somatório de itens cotados, descrição dos itens -------------*/
    let somatorioTotal = []; //armazena todas as informações
    let cotadosTeste = 0; //quantidade de cotações por concorrente
    let somatorioPreco = 0; //somatório do total price por concorrente
    let itensTeste = ""; //descrição dos itens individuais
    let precosTeste = ""; //preços individuais
    for (var prop in orgConcorrentes) {
      cotadosTeste = 0;
      somatorioPreco = 0;
      itensTeste = "";
      precosTeste = "";
      for (let i = 0; i < orgConcorrentes[prop].length; i++) {
        if (orgConcorrentes[prop][i].totalPrice !== 0) {
          cotadosTeste++;
          somatorioPreco = somatorioPreco + orgConcorrentes[prop][i].totalPrice;
          itensTeste !== "" ? itensTeste = itensTeste + ';' + orgConcorrentes[prop][i].itemDescription : itensTeste = orgConcorrentes[prop][i].itemDescription;
          precosTeste !== "" ? precosTeste = precosTeste + ';' + orgConcorrentes[prop][i].totalPrice : precosTeste = orgConcorrentes[prop][i].totalPrice;
        }
      }
      if (cotadosTeste > 0) {
        somatorioTotal.push({
          "corporateName": prop,
          "somatorioTotalPrice": somatorioPreco,
          "cotados": cotadosTeste,
          "itens": itensTeste,
          "preco": precosTeste
        })
      }
    }

    // Variável para controlar se a oportunidade possui mais de um item
    let isMultiItem = false;
    somatorioTotal.map(data => {
      if (data.cotados > 1) {
        isMultiItem = true;
        setIsMultiItemState(true)
      } else {
        isMultiItem = false;
        setIsMultiItemState(false)
      }
    })

    // console.log(somatorioTotal)

    /* ---------------------- Gerando o Top3 ------------------------ */
    let top3 = [];
    let i = 0;
    let aux = 0;
    /* ======================================================================================== */
    if (isMultiItem) { // refatorar 
      if (somatorioTotal.length > 3) {
        setConcorrentes(somatorioTotal)

        let top3MultiItems = {};
        for (let cont in controlItemsManufacturers) {
          aux = 0;
          i = 0;
          top3MultiItems[cont] = [];

          do {
            if (controlItemsManufacturers[cont][i]?.corporateName !== currentCompany) {
              top3MultiItems[cont].push(controlItemsManufacturers[cont][i])
              aux++
            }
            i++;
          } while (aux < 3)

        }
        top3 = top3MultiItems
      } else {
        top3 = [somatorioTotal]
      }
      /* ======================================================================================== */
    } else if (!isMultiItem) {
      if (somatorioTotal.length > 3) {
        somatorioTotal.sort(function (a, b) {
          if (a.somatorioTotalPrice > b.somatorioTotalPrice) {
            return true;
          } else {
            return -1;
          }
        })
        setConcorrentes(somatorioTotal)
        i = 0;
        aux = 0;
        do {
          if (somatorioTotal[i].corporateName !== currentCompany) {
            top3.push(somatorioTotal[i]);
            aux++;
          }
          i++;
        } while (aux < 3);
      } else {
        top3 = somatorioTotal;
      }
      top3?.sort(function (a, b) {
        if (a.somatorioTotalPrice > b.somatorioTotalPrice) {
          return true;
        } else {
          return -1;
        }
      })
    }

    let top3Names = []
    if (isMultiItem) {
      top3[Object.keys(top3)[0]].map(obj => {
        top3Names.push(obj.corporateName)
      })
    } else {
      for (let i = 0; i < top3.length; i++) {
        top3Names.push(top3[i]?.corporateName)
      }
    }

    let top3Price = []
    let concorrentesTop3 = []

    if (isMultiItem) {
      for (let i = 0; i < top3.length; i++) {
        top3Price.push(top3[i]?.totalPrice)
      }
      concorrentesTop3 = top3
    } else if (!isMultiItem) {
      for (let i = 0; i < top3.length; i++) {
        top3Price.push(top3[i]?.somatorioTotalPrice)
      }
      // pegando os dados dos concorrentes top3
      for (let i = 0; i < top3.length; i++) {
        concorrentesTop3.push({
          "corporateName": top3[i].corporateName,
          "cotados": top3[i].cotados,
          "itens": top3[i].itens,
          "preco": top3[i].preco,
          "somatorioTotalPrice": top3[i].somatorioTotalPrice
        })
      }
    }
    setNomeTop3Concorrente(concorrentesTop3)

    /* pegando o price por itens pelos concorrentes */
    let priceConcorrentes = [];
    priceConcorrentes = top3;
    let dataTop3 = [];
    dataTop3 = priceConcorrentes;
    let dataTop3Formatado = []
    dataTop3Formatado = dataTop3;

    /* pegando o price por itens pela empresa */
    let priceEmpresa = [];
    for (let i = 0; i < dataTotalItens.length; i++) {
      priceEmpresa.push(dataEmpresa[i].totalPrice)
    }

    /* ============================ Formatando o gráfico de barras =========================== */
    let itensFormatados = [];
    const controlItemsManufacturersAux = controlItemsManufacturers;

    for (let data in controlItemsManufacturersAux) {
      if (!itensFormatados.includes(data)) {
        itensFormatados.push(data)
      }
    }

    // console.log(dataTotalItens)

    let dataGraphic = [];
    let seriesGraphic = [];
    if (isMultiItem) {
      dataTop3Formatado[Object.keys(dataTop3Formatado)[0]].map(data => {
        if (data?.itemDescription === itensFormatados[0]) {
          dataGraphic.push(data)
        }
      })
      seriesGraphic.push({
        "name": currentCompany,
        "data": [priceEmpresa[0]],
      })
      dataGraphic.map(data => {
        seriesGraphic.push({
          "name": data.corporateName,
          "data": [data.totalPrice],
        })
      })
    } else {
      seriesGraphic.push({
        "name": currentCompany,
        "data": [priceEmpresa[0]],
      })
      top3.map(data => {
        seriesGraphic.push({
          "name": data.corporateName,
          "data": [data.preco],
        })
      })
    }
// console.log(itensFormatados, seriesGraphic)

    let datasetBar = {}
    if (isMultiItem) {
      datasetBar = {
        series: seriesGraphic,
        options: {
          chart: {
            type: 'bar',
            height: 350
          },
          title: {
            text: `Comparativo entre ${currentCompanyName} e os principais concorrentes desta oportunidade`
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [itensFormatados[0]],
          },
          theme: {
            monochrome: {
              enabled: true,
              color: '#0000cd',
              shadeTo: 'light',
              shadeIntensity: 0.65
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "R$ " + val
              }
            }
          }
        }
      }
    } else {
      datasetBar = {
        series: seriesGraphic,
        options: {
          chart: {
            type: 'bar',
            height: 350
          },
          title: {
            text: `Comparativo entre ${currentCompanyName} e os principais concorrentes desta oportunidade`
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: [itensFormatados],
          },
          theme: {
            monochrome: {
              enabled: true,
              color: '#0000cd',
              shadeTo: 'light',
              shadeIntensity: 0.65
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return "R$ " + val
              }
            }
          }
        }
      }
    }
    setDataBar(datasetBar)
    //salvando todos os dados dos top3 concorrentes + empresa
    let fullDataTop3 = [];
    if (isMultiItem) {
      proposal.map(obj => {
        if (obj.itemDescription === itensFormatados[0]) {
          top3Names.map(name => {
            if (obj.corporateName === name && obj.totalPrice !== 0 && !fullDataTop3.includes(obj)) {
              fullDataTop3.push(obj)
            }
          })
          if (obj.corporateName === dataEmpresa[0].corporateName && obj.totalPrice !== 0 && !fullDataTop3.includes(obj)) {
            fullDataTop3.push(obj)
          }
        }
      })
    } else {
      // console.log(dataEmpresa)
      proposal.map(proposalItem => {
        if ((proposalItem.corporateName === top3Names[0] && proposalItem.totalPrice !== 0)
          || (proposalItem.corporateName === top3Names[1] && proposalItem.totalPrice !== 0)
          || (proposalItem.corporateName === top3Names[2] && proposalItem.totalPrice !== 0)
          || (proposalItem.corporateName === dataEmpresa[0].corporateName && proposalItem.totalPrice !== 0)
        ) {
          fullDataTop3.push(proposalItem)
        }
      })

    }

    /*----------- objeto contendo os itens e os top3 cotação ----------------*/
    let fullDataTop3PorItem = {}
    fullDataTop3.map(item => {
      if (fullDataTop3PorItem[item["itemDescription"]] === undefined) {
        fullDataTop3PorItem[item["itemDescription"]] = []
      }
      fullDataTop3PorItem[item["itemDescription"]].push(item)
    })

    /* ordenando este objeto para empresa ser sempre o primeiro */
    let fullDataTop3PorItemClassificado = {};
    for (let prop in fullDataTop3PorItem) {
      let empresa = fullDataTop3PorItem[prop].find((item) => item.corporateName === currentCompany)
      let newProp = fullDataTop3PorItem[prop].filter((item) => item.corporateName !== currentCompany)
      if (fullDataTop3PorItemClassificado[prop] === undefined) {
        fullDataTop3PorItemClassificado[prop] = []
      }
      var teste = newProp.sort(function (a, b) {
        if (a.totalPrice > b.totalPrice) {
          return true;
        } else {
          return -1;
        }
      })
      if (empresa !== undefined) {
        teste.unshift(empresa)
      }
      fullDataTop3PorItemClassificado[prop] = teste
    }

    //Pegando todas as infirmações necessárias do top3 para a tabela
    let fullDataTop3PorItemCompleto = []
    for (var prop in fullDataTop3PorItemClassificado) {
      for (let i = 0; i < fullDataTop3PorItemClassificado[prop].length; i++) {
        if (fullDataTop3PorItemClassificado[prop][i].totalPrice !== 0) {
          fullDataTop3PorItemCompleto.push({
            "corporateName": fullDataTop3PorItemClassificado[prop][i].corporateName,
            "currency": fullDataTop3PorItemClassificado[prop][i].currency,
            "itemDescription": fullDataTop3PorItemClassificado[prop][i].itemDescription,
            "itemNumber": fullDataTop3PorItemClassificado[prop][i].itemNumber,
            "itemPrice": fullDataTop3PorItemClassificado[prop][i].itemPrice,
            "proposalNumber": fullDataTop3PorItemClassificado[prop][i].proposalNumber,
            "quantity": fullDataTop3PorItemClassificado[prop][i].quantity,
            "taxCode": fullDataTop3PorItemClassificado[prop][i].taxCode,
            "totalPrice": fullDataTop3PorItemClassificado[prop][i].totalPrice,
            "um": fullDataTop3PorItemClassificado[prop][i].um
          })
        }
      }
    }
    setFullDataTop3(fullDataTop3PorItemCompleto)
    setFullDataTop3Table(fullDataTop3PorItemCompleto)
  }

  /* ------------------- Função chamada após o click no óculos -------------------- */
  async function initModal(opId) {
    setLoading(true)

    let proposal = []
    await props.initProposalData(props.user, opId.index)
      .then((val) => {
        proposal = val
        // console.log(val);
        setProposalSelectedData(val)
      })
    resultProposals.find(o => Number(o.index) === Number(opId.index));

    if (proposal) {
      findProposal(opId.index, proposal);
      setModalShow(true);
    }
    setLoading(false)
  }

  let formatterBRL = new Intl.NumberFormat('pt-BR');

  let opportunitiesTable = (!loading) ? (
    <MyTable
      title="Endereço"
      currentCompany={currentCompany}
      rangeOfMonths={rangeOfMonths}
      anoSelecionado={anoSelecionado}
      opportunities={data}
      tableRender={tableRender}
      dataTimestamp={dataTimestamp}
      data={tableData.map(item => {
        if (Number(item["dateEnd"]) > 0 && Number(item["dateEnd"]) > 0) {
          let tsDateEnd = new Date(Number(item["dateEnd"]) * 1000);
          let tsDateStart = new Date(Number(item["dateStart"]) * 1000);
          item["dateEnd"] = tsDateEnd.toLocaleDateString("pt-BR");
          item["dateStart"] = tsDateStart.toLocaleDateString("pt-BR");
        }

        let temProposta = false;
        let proposta = null;
        resultProposals.map(proposal => {
          if (proposal.index === item.index) {
            proposta = proposal;
            if (proposal.proposalId !== "") {
              temProposta = true
            }
          }
        })

        item['CC'] = (<p onClick={() => { { if (temProposta) { initModal(item.proposal) }; setOpId(item.index) } }
        } className={temProposta ? "mr-2" : "mr-2 no-click"}> <FontAwesomeIcon size="lg" icon={faGlasses} style={{ color: ((temProposta) ? "black" : "gray") }} /></p>)

        let acumulador = 0;
        if (temProposta) {
          acumulador = proposta.totalPrice
        }
        item["proposta"] = temProposta
        item["apresentado"] = (acumulador) > 0
        item['✓'] = (Number(acumulador) > 0) ? (<FontAwesomeIcon size="lg" icon={faCheck} />) : (<FontAwesomeIcon size="lg" icon={faTimes} style={{ color: "red" }} />)

        item['R$'] = ("R$" + formatterBRL.format(acumulador))
        item["_Status"] = item.purchase;
        return item
      }).filter(item => {
        if (tipoFiltro === "Apresentado" && item.apresentado) {
          return item
        } else if (tipoFiltro === "Declinadas" && !item.apresentado) {
          return item
        } else if (tipoFiltro === "Efetivados" && item["_Status"] === "Confirmado") {
          return item
        } else if (tipoFiltro === "Tudo") {
          return item
        }
      })}
      sources={props.portals}
      fabricantes={props.regex.filter(probe => probe.dashboard === true && probe.subFilter === false).map(o => {o["category"] = props.category.filter(probe => probe.catalogId === o._id) ;return o;})}
      columns={opportunitiesColumns}
      dataFilter={
        tableData.map(obj => {
          obj["proposal"] = resultProposals.find(o => Number(o.index) === Number(obj.index));
          return obj;
        })
      }
      tableData={tableData}
      pagination
      filter
      header
      carregaFabricanteEscolhido={setRegexBTN}
      companyCompletedName={currentCompany}
      initProposalData={props.initProposalData}
      user={props.user}
    />
  ) : (<div className="lds-dual-ring m-4"></div>)

  /* -------- Função chamada após escolha no primeiro select  --------- */
  function filterItens(e) {
    setSelected(e.target.value) //set no item selecionado para imprimir na tela

    // pegando os dados do concorrente selecionado
    let itensConcorrentePronto = []
    for (let i = 0; i < concorrentes.length; i++) {
      if (concorrentes[i].corporateName === e.target.value) {
        itensConcorrentePronto.push({
          "corporateName": concorrentes[i].corporateName,
          "somatorioTotalPrice": concorrentes[i].somatorioTotalPrice,
          "cotados": concorrentes[i].cotados,
          "itens": concorrentes[i].itens,
          "preco": concorrentes[i].preco
        })
      }
    }

    /* Organizando descrição e valores dos itens */
    let itensSelecionado = []
    let dataDescription = []
    let dataPrice = []

    // tratando o caso de existência de apenas 1 tem ('number' = 1 item / 'string' = 2 itens)
    if (typeof (itensConcorrentePronto[0].preco) === 'number') {
      itensSelecionado.push({
        "itens": itensConcorrentePronto[0].itens,
        "preco": itensConcorrentePronto[0].preco
      })

      dataDescription.push(itensSelecionado[0].itens)
      dataPrice.push(itensSelecionado[0].preco)
    } else {
      itensSelecionado.push({
        "itens": itensConcorrentePronto[0].itens.split(";"),
        "preco": itensConcorrentePronto[0].preco.split(";")
      })

      //pegando a descrição dos itens do concorrente
      for (let i = 0; i < itensSelecionado[0].itens.length; i++) {
        dataDescription.push(itensSelecionado[0].itens[i])
      }

      //pegando os preços dos itens do concorrente
      for (let i = 0; i < itensSelecionado[0].preco.length; i++) {
        dataPrice.push(itensSelecionado[0].preco[i])
      }
    }

    //salvando informações do concorrente selecionado para utilizar em outra função
    setDataConcorrente(itensSelecionado)

    /* Pegando o preço dos itens do concorrente cotado pela empresa */
    let dataPriceEmpresa = []
    let cont = 0; //contador para garantir o array empresa vai percorrer ate achar o item igual item do concorrente
    for (let i = 0; i < dataEmpresa.length; i++) {
      if (dataEmpresa[i].itemDescription === dataDescription[cont]) {
        dataPriceEmpresa.push(dataEmpresa[i].totalPrice)
        cont++;
      }
    }

    // alimentando o estado com a descrição dos itens selecionados
    setItensConcorrenteSelecionado(dataDescription)

    //formato que o gráfico 2(donut) espera
    let dataPriceNumber = [];
    dataPrice.map(item => {
      dataPriceNumber.push(Number(item))
    })
    let datasetPie = {
      options: {
        labels: dataDescription,
        theme: {
          monochrome: {
            enabled: true,
            color: '#0000cd',
            shadeTo: 'light',
            shadeIntensity: 0.65
          }
        },
        title: {
          text: "Itens do fabricante selecionado com base no preço cotado"
        },
        responsive: [
          {
            breakpoint: 360,
            options: {
              chart: {
                width: "100%"
              },
              legend: {
                show: false
              }
            }
          }
        ],
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              // console.log(config.w.config.labels[config.dataPointIndex]);
            }
          }
        }
      },
      series: dataPriceNumber
    }
    setDataPie(datasetPie)

    /* ============ Formatando o gráfico de barras =================== */
    let dataPriceFloat = dataPrice.map(preco => Number(preco))
    let datasetBar = {
      series: [{
        name: dataEmpresa[0].corporateName,
        data: dataPriceEmpresa
      }, {
        name: e.target.value,
        data: dataPriceFloat
      }],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        title: {
          text: `Comparativo entre ${currentCompanyName} e o fabicante selecionado com todos os itens cotados pelo concorrente`
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: dataDescription,
        },
        theme: {
          monochrome: {
            enabled: true,
            color: '#0000cd',
            shadeTo: 'light',
            shadeIntensity: 0.65
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "R$ " + val
            }
          }
        }
      }
    }
    
    setDataBar(datasetBar)
  }

  /* ----------------- Função chamada após click segundo select ----------------------- */
  function _itemSelect(e) {
    setItemSelect(e.target.value)

    //pegando o valor que o concorrente deu para o item selecionado
    let priceConcorrente = []
    for (let i = 0; i < dataConcorrente[0].itens.length; i++) {
      if (dataConcorrente[0].itens[i] === e.target.value) {
        priceConcorrente.push(dataConcorrente[0].preco[i])
      }
    }
    let dataPriceSelectEmpresa = []
    for (let i = 0; i < dataEmpresa.length; i++) {
      if (dataEmpresa[i].itemDescription === e.target.value) {
        dataPriceSelectEmpresa.push(dataEmpresa[i].totalPrice)
      }
    }
    let nomes = [];
    nomes.push(currentCompany)
    nomes.push(selected)
    let precos = [];
    precos.push(dataPriceSelectEmpresa[0])
    precos.push(priceConcorrente[0])

    /* ================ Ajustando gráfico de barras ===================*/
    let priceConcorrenteFloat = priceConcorrente.map(preco => Number(preco))
    let datasetBar = {
      series: [{
        name: dataEmpresa[0].corporateName,
        data: dataPriceSelectEmpresa
      }, {
        name: selected,
        data: priceConcorrenteFloat
      }],
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        title: {
          text: `Comparativo entre ${currentCompanyName} e o fabricante selecionado com relação ao item ` + e.target.value
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [e.target.value],
        },
        theme: {
          monochrome: {
            enabled: true,
            color: '#0000cd',
            shadeTo: 'light',
            shadeIntensity: 0.65
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "R$ " + val
            }
          }
        }
      }
    }
    setDataBar(datasetBar)
  }

  function itemSelectTable(e) {
    setSelectedTable(e.target.value)
    let temporario = [];
    for (let i = 0; i < fullDataTop3.length; i++) {
      if (fullDataTop3[i].itemDescription === e.target.value) {
        temporario.push({
          "corporateName": fullDataTop3[i].corporateName,
          "currency": fullDataTop3[i].currency,
          "itemDescription": fullDataTop3[i].itemDescription,
          "quantity": fullDataTop3[i].quantity,
          "um": fullDataTop3[i].um,
          "itemPrice": fullDataTop3[i].itemPrice,
          "totalPrice": fullDataTop3[i].totalPrice,
          "taxCode": fullDataTop3[i].taxCode,
        })
      }
    }
    setFullDataTop3Table(temporario)
  }

  return (
    <>
      <div className="dashboard-wrapper ml-0">
        <div style={{ maxWidth: "100%" }} className="mr-5">
          <Row className="  w-100">
            <Row className="">
              <Col xs={12} className="">
                <NavBreadcrumb />
              </Col>
            </Row>
            <Container fluid className=" float-left w-100">
              <Row className="p-0">
                <Col style={{ maxWidth: "100%" }}>
                  <InputGroup className="container-date-ranking">
                    {/* <DropdownButton
                      as={InputGroup.Prepend}
                      variant="outline-secondary"
                      title={tipoFiltro}
                      className="select-search"
                    >
                      <DropdownButton as={InputGroup.Prepend}
                        variant="outline-primary"
                        title={"Cotação"}>
                      </DropdownButton>

                      {filtroList.map(o => {
                        return (<InputGroup className="mb-3">
                          <InputGroup.Prepend>
                            <InputGroup.Radio checked={formField === o} onClick={(e) => { setFormField(o); }} aria-label="">
                            </InputGroup.Radio>
                            {o}
                          </InputGroup.Prepend>
                        </InputGroup>)
                      })}

                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Radio checked={tipoFiltro === "Apresentado"} onClick={(e) => { setTipoFiltro("Apresentado"); localStorage.setItem("selectedPageRanking", 0) }} aria-label="">
                          </InputGroup.Radio>
                          Apresentado
                        </InputGroup.Prepend>
                      </InputGroup>

                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Radio checked={tipoFiltro === "Efetivados"} onClick={(e) => { setTipoFiltro("Efetivados"); localStorage.setItem("selectedPageRanking", 0) }} aria-label="">
                          </InputGroup.Radio>
                          Efetivados
                        </InputGroup.Prepend>
                      </InputGroup>

                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Radio checked={tipoFiltro === "Declinadas"} onClick={(e) => { setTipoFiltro("Declinadas"); localStorage.setItem("selectedPageRanking", 0) }} aria-label="">
                          </InputGroup.Radio>
                          Declinadas
                        </InputGroup.Prepend>
                      </InputGroup>

                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Radio checked={tipoFiltro === "Tudo"} onClick={(e) => { setTipoFiltro("Tudo"); localStorage.setItem("selectedPageRanking", 0) }} aria-label="">
                          </InputGroup.Radio>
                          Tudo
                        </InputGroup.Prepend>
                      </InputGroup>

                    </DropdownButton> */}

                    <DropdownButton
                      as={InputGroup.Prepend}
                      variant="outline-secondary"
                      title={formSearch}
                    >
                      <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Data Fim</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Mês</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Trimestre</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Semestre</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}>Ano</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setFormSearch(e.target.text) }}><p id="option-select">Nº da Oportunidade</p></Dropdown.Item>
                    </DropdownButton>

                    <FormControl
                      placeholder="De"
                      aria-label="De"
                      type="date"
                      disabled={false}
                      aria-describedby="basic-addon1"
                      value={dateFrom}
                      onChange={(e) => { setDateFrom(e.target.value) }}
                      className={(formSearch === "Data Início" || formSearch === "Data Fim") ? "form-style" : "display-none"}
                    />

                    <FormControl
                      placeholder="Até"
                      aria-label="Até"
                      type="date"
                      disabled={false}
                      aria-describedby="basic-addon1"
                      value={dateTo}
                      onChange={(e) => { setDateTo(e.target.value) }}
                      className={(formSearch === "Data Fim" || formSearch === "Data Fim") ? "form-style" : "display-none"}
                    />
                    <FormControl
                      placeholder="Nº da Oportunidade"
                      aria-label="Nº da Oportunidade"
                      type="text"
                      pattern={/^[0-9\b]+$/}
                      min={7000000000}
                      max={7009999999}
                      aria-describedby="basic-addon1"
                      value={_opNumber}
                      onChange={(e) => {
                        if ((/^[0-9\b]+$/).test(e.target.value)) {
                          _setOpNumber(e.target.value)
                        }
                      }}
                      className={(formSearch === "Nº da Oportunidade") ? "form-style" : "display-none"}
                    />

                    <DropdownButton
                      as={InputGroup.Prepend}
                      variant="outline-secondary"
                      title={mesSelecionado}
                      className={formSearch === "Mês" ? "type-month-ranking" : "display-none"}
                    >
                      <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Janeiro</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Fevereiro</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Março</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Abril</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Maio</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Junho</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Julho</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Agosto</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Setembro</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Outubro</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Novembro</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setMesSelecionado(e.target.text) }}>Dezembro</Dropdown.Item>
                    </DropdownButton>

                    <DropdownButton
                      as={InputGroup.Prepend}
                      variant="outline-secondary"
                      title={trimestreSelecionado}
                      className={formSearch === "Trimestre" ? "type-quarter-ranking" : "display-none"}
                    >
                      <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>1º Trimestre</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>2º Trimestre</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>3º Trimestre</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setTrimestreSelecionado(e.target.text) }}>4º Trimestre</Dropdown.Item>
                    </DropdownButton>

                    <DropdownButton
                      as={InputGroup.Prepend}
                      variant="outline-secondary"
                      title={semestreSelecionado}
                      className={formSearch === "Semestre" ? "type-semester-ranking" : "display-none"}
                    >
                      <Dropdown.Item onBlur={(e) => { setSemestreSelecionado(e.target.text) }}>1º Semestre</Dropdown.Item>
                      <Dropdown.Item onBlur={(e) => { setSemestreSelecionado(e.target.text) }}>2º Semestre</Dropdown.Item>
                    </DropdownButton>

                    <DropdownButton
                      as={InputGroup.Prepend}
                      variant="outline-secondary"
                      title={anoSelecionado}
                      className={formSearch === "Nº da Oportunidade" || formSearch === "Data Fim" ? "display-none" : "type-date-ranking"}
                    >
                      {year.map((o) => {
                        return <Dropdown.Item onBlur={(e) => { setAnoSelecionado(e.target.text) }}>{o}</Dropdown.Item>
                      })}
                    </DropdownButton>
                    <InputGroup.Append>
                      <Button variant="flat" className="button-search-ranking" onClick={(e) => { if (!!e) fillData() }}><FontAwesomeIcon size="lg" icon={faSearch} /></Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
            </Container>
            <Col xs={12} className="m-0 p-3">
              <Card>{opportunitiesTable}</Card>
            </Col>
          </Row>
        </div>
      </div>

      <div className="opportunity-view-wrapper">
        <ModalRanking
          showModal={modalShow}
          setShowModal={setModalShow}
          tag={tag}
          setTag={setTag}
          setItemSelect={setItemSelect}
          selected={selected}
          setSelected={setSelected}
          selectedTable={selectedTable}
          setSelectedTable={setSelectedTable}
          setItensConcorrenteSelecionado={setItensConcorrenteSelecionado}
          setConcorrentes={setConcorrentes}
          setOpId={setOpId}
          setDataBar={setDataBar}
          setModalShow={setModalShow}
          opId={opId}
          currentCompanyName={currentCompanyName}
          itemSelect={itemSelect}
          resultProposals={resultProposals}
          findProposal={findProposal}
          filterItens={filterItens}
          nomeTop3Concorrente={nomeTop3Concorrente}
          setNomeTop3Concorrente={setNomeTop3Concorrente}
          concorrentes={concorrentes}
          itensConcorrenteSelecionado={itensConcorrenteSelecionado}
          _itemSelect={_itemSelect}
          dataBar={dataBar}
          dataPie={dataPie}
          itemSelectTable={itemSelectTable}
          setFullDataTop3Table={setFullDataTop3Table}
          fullDataTop3Table={fullDataTop3Table}
          dataManufacturers={dataManufacturers}
          currentCompany={currentCompany}
          isMultiItemState={isMultiItemState}
          controlItemsManufacturersData={controlItemsManufacturersData}
          dataEmpresa={dataEmpresa}
          proposalSelectedData={proposalSelectedData}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    error: state.opportunities.error,
    portals: state.portals.portals,
    proposals: state.proposals.proposals,
    regex: state.regex.regex,
    companies: state.companies.companies,
    category: state.category.category,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initOpportunities: (usuario) => dispatch(storeActions.initOpportunities(usuario)),
    initPortals: (usuario) => dispatch(storeActions.initPortals(usuario)),
    initProposalData: (usuario, index) => dispatch(storeActions.initProposalData(usuario, index)),
    getProposals: (usuario, dateFrom, dateTo) => dispatch(storeActions.getProposals(usuario, dateFrom, dateTo)),
    fetchData: (token) => dispatch(storeActions.fetchData(token)),
    setUserInteraction: (userId, companyId, opportunityId) => dispatch(storeActions.setUserInteraction(userId, companyId, opportunityId)),
    setPastOpportunities: (userId, companyId, dateFrom, dateTo, index) => dispatch(storeActions.setPastOpportunities(userId, companyId, dateFrom, dateTo, index)),
    getProposalById: (usuario, index) => dispatch(storeActions.getProposalById(usuario, index)),
    initRegex: () => dispatch(storeActions.initRegex()),
    notify: (notification, delay) =>
      dispatch(storeActions.notify(notification, delay)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ranking);