import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./TopMenu.scss";
import { connect } from "react-redux";
import { VscFeedback } from "react-icons/vsc";
import { Feedback } from "./Feedback";
import UserInfo from "./UserInfo/UserInfo";
import {resetFiltros} from "../pages/DashboardOportunidades/DashboardOportunidades"
import * as storeActions from "../../store/actions/index";
import LogoPuro from "../../assets/petrolead-puro.png"
import SourcesMenu from "../../components/pages/DashboardOportunidades/SourcesMenu/SourcesMenu";
import voltar from "../../assets/Prancheta 4 cópia 7icons.png"
import reiniciar from "../../assets/Prancheta 4 cópia 6icons.png"

function TopMenu(props) {
  const [selectedPortal, setSelectedPortal] = useState("");
  const [destinyRegex, setDestinyRegex] = useState("");
  const [opNumber, setOpNumber] = useState("");
  const [opProduct, setOpProduct] = useState("");
  const [opSearchRegex, setOpSearchRegex] = useState("");
  const [formSearch, setFormSearch] = useState("Nº da Oportunidade");
  const [originTab, setOriginTab] = useState(JSON.parse(localStorage.getItem("memory"))?.originTab ? JSON.parse(localStorage.getItem("memory")).originTab : "Minhas Participações");
  const [adressFilters, setAdressFilters] = useState([]);
  const [showRejectable, setShowRejectable] = useState(false);
  const [mainSelected, setMainSelected] = useState([]);
  const [useCompanyList, setUseCompanyList] = useState()
  const [nameCompany, setNameCompany] = useState()
  const [feedbackIsClicked, setFeedbackIsClicked] = useState(false)

// console.log(resetFiltros)
  
  // console.log(props.portals)
function teste() {
  props.fetchData(props.user)
}
function refazer() {
  resetFiltros()
}

  let userInfo, userInfoClass;
  if (props.user) {
    userInfoClass = props.user ? "userLogged user-info-active" : "";
    userInfo = props.user ? props.user.data.name : null;
  }

  useEffect(() => {
    let arrayCompanyList = props.user.data.companyIdList?.split(',')
    let arrayCompanyListTemp = []
    props.companies?.map((companies) => {
      arrayCompanyList?.map(idCompany => {
        if (idCompany === companies._id) {
          arrayCompanyListTemp.push({
            "id": idCompany,
            "name": companies.name,
            "uf": companies.uf,
          })
        }
      })
      setUseCompanyList(arrayCompanyListTemp)

      let companyName = []
      let aux
      if (arrayCompanyListTemp?.length > 0) {
        arrayCompanyListTemp.map(data => {
          if (data.id === props.user.data.companyId) {
            aux = data.name.split(' ')[0]
            aux = aux[0].toUpperCase() + aux.substr(1).toLocaleLowerCase()
            companyName.push(aux + ' ' + data.uf)
          }
        })
      }
      setNameCompany(companyName)
    })
  }, [])

  const logoutHandler = () => {
    props.logout();
    props.dataErase();
  }
  return (
    <div
      id="topMenu"
      className="fixed-top top-menu-container d-flex justify-content-between align-items-center"
    >
      <div className=" d-flex align-items-center">
        <h3 className=" mb-0"><img className="libo-topmenu-icon" src={LogoPuro}></img> {nameCompany}</h3>
      </div>
      {/* <SourcesMenu
          handlerEvent={setSelectedPortal}
          baseEvent={() => {
            props.fetchData(props.user)
          }}
          resetEvent={() => {
            setDestinyRegex("");
            resetFilters();

            //Desenvolver mecanismo automático
            setOpNumber("")
            setOpProduct("")
            setOpSearchRegex("")
            setFormSearch("Nº da Oportunidade");

            let comeBackToFilter = "Minhas Participações";

            if (props.opportunities.some(probe => probe.origin === "Inbox")) {
              comeBackToFilter = "Inbox";
            } else if (props.opportunities.some(probe => probe.origin === "Aberto")) {
              comeBackToFilter = "Aberto";
            }
            // console.log(comeBackToFilter)
            setOriginTab(comeBackToFilter);

            localStorage.removeItem("memory");
            localStorage.removeItem("dayOpps");
            setAdressFilters([]);
            setShowRejectable(false);
          }}
          clearEvent={setMainSelected}
        />  */}
        <div>
        <button className="sourcesmenu-container1" onClick={() => {
          refazer()
        }} >   
<img src={voltar}></img>
        </button>
        </div>
        <div>
        <button className="sourcesmenu-container2" onClick={() => {
          teste()
        }}>
<img src={reiniciar}></img>
        </button>
        </div>
      <div className="pr-2 d-flex align-items-center user-info-container">
        <UserInfo
          userCompany={useCompanyList}
          userInfo={userInfo}
          userInfoClass={userInfoClass}
          logoutHandler={logoutHandler}
          props={props}
          user={props.user}
        />
        {/* <button className="button-feedback" onClick={() => setFeedbackIsClicked(!feedbackIsClicked)}>
          <VscFeedback size={"2.2rem"} style={{ "color": " rgba(56, 56, 56,0.9)" }} />
        </button> */}
      </div>
      {feedbackIsClicked &&
        <Feedback showContext={true} setFeedbackIsClicked={setFeedbackIsClicked} />
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    notifications: state.notifications.notifications,
    companies: state.companies.companies,
    portals: state.portals.portals,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(storeActions.logout()),
    dataErase: () => dispatch(storeActions.dataErase()),
    putUser: (obj, header) => dispatch(storeActions.putUser(obj, header)),
    fetchData: (token) => dispatch(storeActions.fetchData(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);