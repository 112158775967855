import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Pagination from "./Pagination/Pagination";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import "./DataTable.scss";

const sortArrayOfObjects = (array, selector, direction) => {
  if (direction === "ASC") {
    array.sort(function (a, b) {
      if (
        a[selector].toString().toLowerCase() >
        b[selector].toString().toLowerCase()
      ) {
        return 1;
      }
      if (
        a[selector].toString().toLowerCase() <
        b[selector].toString().toLowerCase()
      ) {
        return -1;
      }
      return 0;
    });
  } else if (direction === "DES") {
    array.sort(function (a, b) {
      if (
        a[selector].toString().toLowerCase() <
        b[selector].toString().toLowerCase()
      ) {
        return 1;
      }
      if (
        a[selector].toString().toLowerCase() >
        b[selector].toString().toLowerCase()
      ) {
        return -1;
      }
      return 0;
    });
  }
};

const downloadTable = (o) => {
  let fileName = "";
  let formData = o.map(item => {
    fileName = String(item.opportunityId.index);
    return {
      "Número": item.index,
      "Descrição Obj. Contratação": item.description,
      "Data Início": item.dateStart,
      "Data Fim": item.dateEnd,
      "Origem": item.origin,
      "Status": item.status,
      "Visto": item.active ? "Sim" : "Não"
    }
  })

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(formData);
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

export default function DataTable({
  title,
  data,
  columns,
  dataType,
  callback,
  categorySelected,
  mainManufacturer,
  showRejectable
}) {
  const [itemsShow, setItemsShow] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [sortIndex, setSortIndex] = useState(-1);
  const [sortAscDesc, setSortAscDesc] = useState("");
  const [appliedFilters, setAppliedFilters] = useState([]);

  if (!data) {
    data = [];
  }

  data?.sort(function (a, b) {
    if (a?.index.props?.children > b?.index.props?.children) {
      return true;
    } else {
      return -1;
    }
  })

  const options = [10, 15, 20, 25, 30, 50, 100];
  let numberOfPages;
  const requiredPages = data.length / options[itemsShow];

  if (requiredPages % 1 !== 0) {
    if (requiredPages % 1 >= 5) {
      numberOfPages = Math.floor(requiredPages);
    } else {
      numberOfPages = Math.floor(requiredPages) + 1;
    }
  } else {
    numberOfPages = requiredPages;
  }

  const selectors = columns.map((column) => column["selector"]);
  const overlays = columns.map((column) => {
    if (column["overlay"] === true) {
      return column["selector"];
    }
  });

  const sliceIndex = Number(selectedPage) * options[itemsShow];

  let filteredData = [];
  let foundId = [];
  let aux = 0;
  let dataSorted;

  if (appliedFilters.length > 0) {
    for (let i = 0; i < appliedFilters.length; i++) {
      for (let j = 0; j < selectors.length; j++) {
        data.forEach((element) => {
          if (
            element[selectors[j]]
              .toString()
              .toLowerCase()
              .includes(appliedFilters[i].toLowerCase())
          ) {
            if (!foundId.includes(element._id)) {
              foundId[aux] = element._id;
              filteredData[aux] = element;
              aux++;
            }
          }
        });
      }
    }
    dataSorted = [...filteredData];
  } else {
    dataSorted = [...data];
  }

  if (sortIndex >= 0) {
    sortArrayOfObjects(dataSorted, selectors[sortIndex], sortAscDesc);
  }

  const dataToShow = dataSorted.slice(
    sliceIndex,
    sliceIndex + options[itemsShow]
  );

  const handleSortFilterSelect = (index) => {
    if (index === -1 || index !== sortIndex) {
      setSortIndex(index);
      setSortAscDesc("DES");
    } else if (index === sortIndex && sortAscDesc === "DES") {
      setSortAscDesc("ASC");
    } else if (index === sortIndex && sortAscDesc === "ASC") {
      setSortIndex(-1);
      setSortAscDesc("");
    }
  };

  const tableHeader = columns.map((column, index) => {
    let activeFilter = "";
    if (sortIndex === index) {
      activeFilter = "show ";
      if (sortAscDesc === "DES") {
        activeFilter += " arrow-up";
      } else {
        activeFilter += " arrow-down";
      }
    }
    return (
      <th className="datatable-header-col" key={`${column}-${index}`}>
        <div
          className="d-flex flex-row"
          onClick={() => {
            handleSortFilterSelect(index);
          }}
        >
          <p>
            {column.name}
          </p>
          <i className={"arrow-up ml-2 " + activeFilter}></i>
        </div>
      </th>
    );
  });

  const tableBody = dataToShow.map((row, index) => {
    const rowClass =
      index % 2 === 0 ? "datatable-row-even" : "datatable-row-odd";
    let active = row.active ? null : "unviewed";
    return (
      <tr
        onClick={() => callback ? callback(row) : null}
        className={"datatable-row " + rowClass}
        key={`${row}-${index}`}
      >
        {selectors.map((col) => (

          <th className={"datatable-row-col " + active} key={`${row}-${col}-${index}`}>
            {row[col]}
          </th>
        ))}
      </tr>
    );
  });

  const onPageChanged = (index) => {
    if (index < 1) {
      index = 0;
    } else if (index >= numberOfPages - 1) {
      index = numberOfPages - 1;
    }
    setSelectedPage(index);
  };

  const onItemsShowChanged = (index) => {
    setItemsShow(index);

    setSelectedPage(0);
  };

  let PaginationContent;

  /* Legenda das opções selecionadas para exibição da tabela */
  let titleContent;
  if (title) {
    titleContent = (
      <Col xs={12}>
        <p className="datatable-legend mt-2">{'Filtro: '}{categorySelected} {mainManufacturer ? "/" : ""} {mainManufacturer} {showRejectable === true ? "/ " + "Oportunidades não filtradas" : ""}</p> {/*{title} {"/"}*/}
      </Col>
    );
  }

  PaginationContent = (
    <Col className="d-flex flex-row justify-content-end align-content-center mt-3 mr-3">
      <Pagination
        title={dataType !== undefined ? dataType : title}
        itens={data.length}
        options={options}
        selectedPage={selectedPage}
        selectedOption={itemsShow}
        onPageChanged={onPageChanged}
        onItemsShowChanged={onItemsShowChanged}
        pagesNumber={numberOfPages}
        downloadTable={() => { downloadTable(data) }}
        dataToShow={dataToShow}
      />
    </Col>
  );

  return (
    <Row className="w-100">
      {/* Legenda das opções selecionadas para exibição da tabela */}
      {titleContent}

      {/* {FilterContent}*/} {/* sub-filtro (Filtre os campos) ** DESATIVADO ** */}

      <Col xs={12} className="w-100 datatable-outer">
        <table className="w-100 datable-main">
          <thead>
            <tr className="datable-head-row">{tableHeader}</tr>
          </thead>
          <tbody>{tableBody}</tbody>
        </table>
      </Col>
      {PaginationContent}
    </Row>
  );
}